import React from 'react';
import Slide from 'react-awesome-reveal';

import useIphoneSafariVersion, { isIphone } from '../../hooks/useIphoneSafariVersion'

const SlideEffect = (props) => {
  const iPhone = isIphone()
  const compatible = (!iPhone || (iPhone && useIphoneSafariVersion().major > 13))
  if (!compatible) {
    return (
      <>
        {props.children}
      </>
    );
  }
  return (
    <Slide {...props.slideDirection} triggerOnce>
      {props.children}
    </Slide>
  )

}
export default SlideEffect

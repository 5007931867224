

export const isIphone = () => {
  const myRe = /.*iPhone.*/gm;
  const result = myRe.exec(navigator.userAgent);
  return (result !== null)
}

const useIphoneSafariVersion = () => {
  let versionString = '8_0'
  const myRe = /CPU iPhone OS (\S*) .*/gm;
  const result = myRe.exec(navigator.userAgent);
  versionString = result && result.length && result[1]
  const versionParts = versionString.split('_')
  const version = {
    major: parseInt(versionParts[0], 10),
    minor: parseInt(versionParts[1], 10),
  }
  return version
}

export default useIphoneSafariVersion;

import React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
// import Img from 'gatsby-image'
import { GatsbyImage } from "gatsby-plugin-image"

import ClientOnly from '../../components/ClientOnly';
import SlideEffect from '../../components/SlideEffect'

export default (props) => {
  const data = props.data[props.componentId];
  const indicationIcon = (data && data.indicationIcon && data.indicationIcon.gatsbyImageData);
  const textImage = (data && data.textImage && data.textImage.gatsbyImageData);
  const direction = (data && data.direction || 'row');
  const oppositeDirection = (direction === 'row') ? 'row-reverse' : 'row';
  const slideDirection = (direction === 'row') ? { direction: 'right'} : { direction: 'left' };
  return (
    <div style={{overflowX: 'hidden', contentVisibility: 'auto'}}>

    <ClientOnly withLoading={false}>
    <SlideEffect slideDirection={slideDirection}>

    <Box m={3}>
    <Grid container spacing={3} direction={direction} alignItems="center" justify="center">
      <Grid item lg={3} xl={3} md={3} sm={12} xs={12}>
        <Grid container alignItems="center" justify="center">
          <Grid item lg={12} xl={12} md={9} sm={9} xs={9}>
            <div style={{padding: '2rem'}}>
              {indicationIcon && <GatsbyImage image={indicationIcon} alt="icon" />}
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Grid item lg={9} xl={9} md={9} sm={12} xs={12}>
        <Grid container direction={oppositeDirection} alignItems="center" spacing={1}>
          <Grid item lg={3} xl={3} md={3} sm={12} xs={12}>
            {textImage && <GatsbyImage image={textImage} alt="image" />}
          </Grid>
          <Grid item lg={9} xl={9} md={9} sm={12} xs={12}>
            <Typography component="h3" variant="h3" align="center">
              {data.text.text}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
    </Box>
    </SlideEffect>
    </ClientOnly>
    </div>
  )
}